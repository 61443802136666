.custom-color {
  color: rgba(15, 151, 95, 1);
}

.font {
  font-size: 14px;
}

.font-size {
  font-size: 50px;
}

.Launching {
  font-size: 18px;
  color: rgba(15, 151, 95, 1);
  margin-bottom: 30px;
}

.Scrap {
  margin-bottom: 20px;
  margin-top: 5px;
}

.button {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 0px;
}

@media (max-width: 640px) {
  .font-size {
    font-size: 30px;
  }
}

@media (min-width: 641px) {
  .font-size {
    font-size: 50px;
  }
}

@media (min-width: 375px) {
  .easy{
    @apply h-[72px]  /* Apply Tailwind classes */
  }
}

@media (min-width: 320px) {
  .easy{
    @apply h-[72px]  /* Apply Tailwind classes */
  }
}
@media (min-width: 768px) {
  .easy{
    @apply h-[105px]  /* Apply Tailwind classes */
  }
}
@media (min-width: 768px) {
  .easy{
    @apply h-[105px]  /* Apply Tailwind classes */
  }
}
@media (min-width: 1440px) {
  .easy{
    @apply h-[80px]  /* Apply Tailwind classes */
  }
}